import React, { createContext, useState, useEffect } from 'react'
import sanityClient from '../Client'
export const ContentContext = createContext()

const ContentContextProvider = props => {
	const [videoArray, setVideoArray] = useState([])
	const videoQuery = `*[_type == "video"][!(_id in path('drafts.**'))] | order(date desc){
		_id, clientWork, title, thumbnail, client[]->{clientName, _id, logo}, categories[]->{category, _id}}
		`

	useEffect(() => {
		sanityClient.fetch(videoQuery).then(video => {
			const videoArray = []
			video.forEach(video => {
				videoArray.push(video)
			})

			setVideoArray(videoArray)
		})
		return
	}, [videoQuery])

	return (
		<ContentContext.Provider
			value={{ videoArray }}
		>
			{props.children}
		</ContentContext.Provider>
	)
}

export default ContentContextProvider
