import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { device } from '../../style-variables/mediaQueries'

const VideoContainer = styled.div`
	min-width: 100%;
	height: auto;
	margin-bottom: 2rem;
	padding: 0 2rem;
	@media ${device.mediaMinSmall} {
		height: 30vh;
	}
	@media ${device.mediaMinMedium} {
		height: 50vh;
	}
	@media ${device.mediaMinLarge} {
		height: 65vh;
	}
`

const Player = ({ video }) => {
	return (
		<VideoContainer>
			<script src="https://player.vimeo.com/api/player.js"></script>
			<ReactPlayer
				className='react-player'
				url={`https://vimeo.com/${video.vimeoLink}`}
				width='100%'
				height='100%'
				controls={true}
			/>
		</VideoContainer>
	)
}

export default Player
