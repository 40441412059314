import React from 'react'
import styled from 'styled-components'
import { device } from '../../style-variables/mediaQueries'
import sanityClient from '../../Client'
import imageUrlBuilder from '@sanity/image-url'
const builder = imageUrlBuilder(sanityClient)
function urlFor(source) {
	return builder.image(source)
}

const StyledScreengrabs = styled.ul` 
	margin: 0 auto;
	padding: 0 2rem;
	display: grid;
	gap: 30px;
	width: 100%;
	grid-template-columns: 1fr;
    max-width: 90vw;
    @media ${device.mediaMinMedium} {
        grid-template-columns: 1fr 1fr;
	}
    @media ${device.mediaMinLarge} {
        max-width: 1280px;
        grid-template-columns: 1fr 1fr 1fr;
    }
	li {
		list-style: none;
		display: flex;
		img {
			width: 100%;
		}
	}
`

const Screengrabs = ({ screengrabs }) => {
    return (
        <StyledScreengrabs>
                {screengrabs.map((grab) => {
                    return (
                        <li key={grab._key}>
                            <img 
                                src={
                                    urlFor(grab)
                                    .auto('format')
                                    .quality(50)
                                    .url()
                                } 
                                alt="" />
                        </li>
                    )
                })}
        </StyledScreengrabs>
    )
}

export default Screengrabs;