import React from 'react'
import styled from 'styled-components'
// import sanityClient from '../../Client'
import footerLogo from '../../images/GH-White16.png'
import colors from '../../style-variables/colors';

const StyledFooter = styled.footer`
    background-color: ${colors.background};
    position: relative;
    /* z-index: -1; */
    width: 100vw;
    text-align: center;
    bottom: 0;
    padding: 1rem 0;
`

const FooterLogo = styled.img`
    max-width: 50px;
`

const Footer = () => {
//   const [footer, setFooter] = useState([])


// useEffect(() => {
// const footerQuery = `*[_type == "footer"]`
// sanityClient.fetch(footerQuery).then(footer => {
// setFooter(footer)
//   footer.forEach(footer => {
//     setFooter(footer)
//   })
// })

// }, [])

    return (
       <StyledFooter>
        {/* <FooterDesc>{footer.companyInfo}</FooterDesc> */}
        <FooterLogo src={footerLogo} alt="Footer Logo" />
       </StyledFooter>
    )
}

export default Footer
