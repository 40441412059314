import React from 'react'
import styled from 'styled-components'
import Player from '../player/player.component'
import Spinner from '../spinner/spinner.component'
import Screengrabs from '../screengrabs/screengrabs.component'

const VideoPageWrapper = styled.div`
	text-align: center;
`

const VideoInfo = styled.div`
	margin-bottom: 2rem;
`

const VideoTitle = styled.h2`
	margin: 2rem 0 0 0;
	@media screen and (max-width: 1000px) {
		font-size: 18px;
	}
`

const VideoDesc = styled.p`
	margin: 0;
`

const VideoExtended = ({ video }) => {
	switch (video) {
		case undefined:
			return null
		case 'noVid':
			return (
			<div>
				<Spinner></Spinner>
				<VideoTitle>Video Not Found :(</VideoTitle>
			</div>
			)
		default:
			return (
				<VideoPageWrapper>
					<VideoInfo>
						<VideoTitle>{video.title}</VideoTitle>
						{video.client
							? video.client.map((client, id) => {
									return <VideoDesc key={id}> {client.clientName} </VideoDesc>
							})
							: null}
						{/* {video.description ? <About video={video} /> : null} */}
					</VideoInfo>
						<Player video={video} />
					{video.screengrabs ? <Screengrabs screengrabs={video.screengrabs}/> : null}
				</VideoPageWrapper>
			)
	}
}

export default VideoExtended
